<template>
	<div class="base-box">
		<el-form
			ref="baseInfoform"
			:model="baseInfo"
			:rules="rules"
			label-width="140px"
		>
			<div class="images-box">
				<el-form-item prop="idCardFaceImg">
					<div class="images-item">
						<div class="images-uploader">
							<OssUpload
								@change="getFaceImg"
								suffix="gif;jpg;png;jpeg"
								:oldImg="baseInfo.idCardFaceImg"
								ocrType="ID_CARD_FRONT"
								:isUpdate="update"
								:isOneImg="true"
							></OssUpload>
						</div>
						<div class="images-title">
							<span>*</span>法定人身份证人像面
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="idCardNationalEmblemImg">
					<div class="images-item">
						<div class="images-uploader">
							<OssUpload
								@change="getNationalEmblemImg"
								suffix="gif;jpg;png;jpeg"
								:oldImg="baseInfo.idCardNationalEmblemImg"
								ocrType="ID_CARD_BACK"
								:isUpdate="update"
								:isOneImg="true"
							></OssUpload>
						</div>
						<div class="images-title">
							<span>*</span>法定人身份证国徽面
						</div>
					</div>
				</el-form-item>
			</div>
			<el-form-item label="所在城市" prop="provinceCode">
				<div style="float:left">
					<el-form-item prop="provinceCode">
						<el-select
							v-model="baseInfo.provinceCode"
							placeholder="请选择省份"
							@change="provinceChange"
						>
							<el-option
								v-for="item in provinceList"
								:key="item.code"
								:label="item.name"
								:value="item.code"
							></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div style="float:left; padding:0 10px" :span="1">-</div>
				<div style="float:left" :span="11">
					<el-form-item prop="cityCode">
						<el-select
							v-model="baseInfo.cityCode"
							placeholder="请选择城市"
						>
							<el-option
								v-for="item in cityList"
								:key="item.code"
								:label="item.name"
								:value="item.code"
							></el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-form-item>
			<el-form-item label="经营地址" prop="businessAddress">
				<el-input
					v-model="baseInfo.businessAddress"
					placeholder="请填写详细的经营地址"
				></el-input>
			</el-form-item>
			<el-form-item
				:label="`${userType == '1' ? '负责人' : '法人代表'}姓名`"
				prop="legalName"
			>
				<el-input
					v-model="baseInfo.legalName"
					:placeholder="
						`请填写${userType == '1' ? '负责人' : '法人代表'}姓名`
					"
				></el-input>
			</el-form-item>
			<el-form-item
				:label="`${userType == '1' ? '负责人' : '法人代表'}身份证号`"
				prop="legalIdCardNo"
			>
				<el-input
					v-model="baseInfo.legalIdCardNo"
					maxlength="18"
					:placeholder="
						`请填写${
							userType == '1' ? '负责人' : '法人代表'
						}身份证号`
					"
				></el-input>
			</el-form-item>
			<el-form-item label="身份证有效期" prop="legalIdCardValidity">
				<el-col :span="16">
					<el-date-picker
						placeholder="选择日期"
						v-model="baseInfo.legalIdCardValidity"
						value-format="yyyy-MM-dd"
						:disabled="foreverCheck"
						type="date"
						style="width: 100%;"
					></el-date-picker>
				</el-col>
				<el-col :span="5" style="padding-left:20px">
					<el-checkbox
						v-model="foreverCheck"
						@change="baseInfo.legalIdCardValidity = '0'"
						>永久有效</el-checkbox
					>
				</el-col>
			</el-form-item>
			<el-form-item label="运营人员" v-if="type == 'org'">
				{{ baseInfo.operaterUserNo }}
			</el-form-item>
			<el-form-item
				label="联系人手机号"
				prop="contactsMobilePhone"
				placeholder="请输入联系人手机号"
			>
				<el-input
					v-model="baseInfo.contactsMobilePhone"
					maxlength="11"
				></el-input>
			</el-form-item>
			<el-form-item label="驳回原因" v-if="checkMsg">
				<el-input type="textarea" v-model="checkMsg"></el-input>
			</el-form-item>
		</el-form>
		<el-row :gutter="20">
			<el-col :span="12" :offset="6">
				<!-- <el-button>返回</el-button> -->
				<el-button
					type="primary"
					v-if="active != 4"
					@click="nextClick('baseInfoform')"
					>下一步</el-button
				>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { AgentApi, CommonApi, UserApi } from "@/api";
import { formatOCRDate } from "@/utils/tools";
export default {
	data() {
		var checkParent = (rule, value, callback) => {
			if (!value && !this.hasParent) {
				return callback(new Error("上级机构不能为空"));
			} else {
				callback();
			}
		};
		var checkDate = (rule, value, callback) => {
			if (!value && !this.foreverCheck) {
				return callback(new Error("有效期不能为空"));
			} else {
				callback();
			}
		};
		var checkFaceImg = (rule, value, callback) => {
			if (!this.baseInfo.idCardFaceImg) {
				return callback(new Error("请上传身份证人像面"));
			} else {
				value = this.baseInfo.idCardFaceImg;
				callback();
			}
		};
		var checkNationalEmblemImg = (rule, value, callback) => {
			if (!this.baseInfo.idCardNationalEmblemImg) {
				return callback(new Error("请上传身份证国徽面"));
			} else {
				value = this.baseInfo.idCardNationalEmblemImg;
				callback();
			}
		};
		return {
			baseInfo: { parentAgentNo: "", cityCode: "", operaterUserNo: "" },
			options: [],
			pageNo: 1,
			pageSize: 10,
			cityList: [],
			operaterUserList: [],
			hasParent: false,
			foreverCheck: false,
			rules: {
				idCardFaceImg: [{ validator: checkFaceImg, trigger: "change" }],
				idCardNationalEmblemImg: [
					{ validator: checkNationalEmblemImg, trigger: "change" }
				],
				parentAgentNo: [{ validator: checkParent, trigger: "blur" }],
				provinceCode: [
					{ required: true, message: "请选择省份", trigger: "change" }
				],
				cityCode: [
					{ required: true, message: "请选择城市", trigger: "change" }
				],
				businessAddress: [
					{
						required: true,
						message: "请输入经营地址",
						trigger: "blur"
					}
				],
				legalName: [
					{
						required: true,
						message: "请输入法人代表姓名",
						trigger: "blur"
					}
				],
				legalIdCardNo: [
					{
						required: true,
						message: "请输入法人代表身份证号码",
						trigger: "blur"
					}
				],
				legalIdCardValidity: [
					{ validator: checkDate, trigger: "change" }
				],
				legalMobileNo: [
					{
						required: true,
						message: "请输入法人代表手机号",
						trigger: "blur"
					}
				],
				operaterUserNo: [
					{
						required: true,
						message: "请选择运营人员",
						trigger: "change"
					}
				],
				contactsMobilePhone: [
					{
						required: true,
						message: "请输入联系人手机号",
						trigger: "blur"
					}
				]
			},
			userType: "1"
		};
	},
	props: {
		provinceList: {
			type: Array,
			default: []
		},
		update: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: "org"
		},
		contactMobileNo: {
			type: String,
			default: ""
		},
		updateData: {
			type: Object,
			default: {}
		},
		checkMsg: {
			type: String,
			default: ""
		}
	},
	watch: {
		updateData: {
			handler(val) {
				if (val && this.update) {
					this.baseInfo = val;
					if (this.baseInfo.legalIdCardValidity == "0") {
						this.foreverCheck = true;
					}
					for (let i = 0; i < this.provinceList.length; i++) {
						if (val.provinceCode == this.provinceList[i].code) {
							this.provinceName = this.provinceList[i].name;
							this.cityList = this.provinceList[i].children;
						}
					}
				}
			},
			immediate: true
		},
		provinceList: {
			handler(val) {
				if (val.length > 0 && this.update) {
					for (let i = 0; i < this.provinceList.length; i++) {
						if (
							this.baseInfo.provinceCode ==
							this.provinceList[i].code
						) {
							this.cityList = this.provinceList[i].children;
						}
					}
				}
			},
			immediate: true
		}
	},
	created() {
		this.userType = this.$route.query.userType;
		UserApi.user.list(1, 2000).then(res => {
			if (res.success) {
				this.operaterUserList = res.data;
			}
		});
		if (this.contactMobileNo) {
			this.baseInfo.contactsMobilePhone = this.contactMobileNo;
		}
		AgentApi.beforeNewAgent().then(res => {
			this.$set(
				this.baseInfo,
				"operaterUserNo",
				res.data.operateUserName
			);
		});
	},
	methods: {
		getFaceImg(fileList, filename, ocrData) {
			this.baseInfo.idCardFaceImg = filename;
			if (JSON.stringify(ocrData) != "{}") {
				this.$set(this.baseInfo, "legalName", ocrData["姓名"].words);
				this.$set(
					this.baseInfo,
					"legalIdCardNo",
					ocrData["公民身份号码"].words
				);
			}
		},
		getNationalEmblemImg(fileList, filename, ocrData) {
			this.baseInfo.idCardNationalEmblemImg = filename;
			if (JSON.stringify(ocrData) != "{}") {
				this.foreverCheck = false;
				this.$set(
					this.baseInfo,
					"legalIdCardValidity",
					formatOCRDate(ocrData["失效日期"].words)
				);
			}
		},
		async remoteMethod(query) {
			if (query != "") {
				let res = await AgentApi.listUpperOrg(
					this.pageNo,
					this.pageSize,
					{ searchValue: query }
				);
				if (res.success) {
					this.options = res.data;
				}
			} else {
				this.options = [];
			}
		},
		provinceChange(val) {
			this.baseInfo.cityCode = "";
			this.provinceList.map(e => {
				if (val == e.code) {
					this.cityList = e.children;
				}
			});
		},
		nextClick(formName) {
			this.baseInfo.parentAgentNo = this.baseInfo.parentAgentNo
				? this.baseInfo.parentAgentNo
				: "0";
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$emit("change", this.baseInfo, "next");
				} else {
					return false;
				}
			});
		}
	}
};
</script>
